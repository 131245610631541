import request from "./request";
// 获取图片验证码
export const ImgCodeApi = () => request.get("/admin/login/get_img_code");
// 登录
export const LoginApi = (params) => request.post("/admin/login/login", params);
// 自动登录
export const AutoLoginApi = () => request.get("/admin/login/auto_login");
//公共模块  获取主机域名
export const GetHostApi = () => request.get("/admin/Common/get_host");

// 充电桩监控信息
export const GetPilesMonitorApi = (params) =>
  request.post("/admin/Piles/monitor", params);

//公共模块  上传图片
export const UploadApi = (params) =>
  request.post("/admin/Common/upload_img", params);

// 工单 工单列表
export const WorkOrderListApi = (params) =>
  request.post("/admin/work_order/get_list_data", params);
// 工单 工单详情
export const WorkOrderInfoApi = (params) =>
  request.post("/admin/work_order/get_info", params);
// 工单 工单的处理记录
export const WorkOrderRecordsApi = (params) =>
  request.post("/admin/work_order/processing_records", params);
// 工单 创建工单
export const WorkOrderCreateApi = (params) =>
  request.post("/admin/work_order/create_work_order", params);
// 工单 指派处理人员
export const WorkOrderHandlerApi = (params) =>
  request.post("/admin/work_order/assign_handler", params);
// 管理员选项
export const AdminOptionApi = (params) =>
  request.post("/admin/Admin/get_options", params);

// 工单模板 模板列表
export const TemplateListApi = (params) =>
  request.post("/admin/work_order_template/get_list_data", params);
// 工单模板 模板选项
export const TemplateOptionApi = (params) =>
  request.post("/admin/work_order_template/get_options", params);
//工单模板  模板详情
export const TemplateInfoApi = (params) =>
  request.post("/admin/work_order_template/get_template", params);
// 工单模板 新增模板
export const TemplateAddApi = (params) =>
  request.post("/admin/work_order_template/add_template", params);
// 工单模板 更新模板
export const TemplateUpdateApi = (params) =>
  request.post("/admin/work_order_template/update_template", params);
// 工单模板 查询模板的所有字段
export const TemplateFieldsApi = (params) =>
  request.post("/admin/work_order_template/get_fields", params);
// 工单模板 为模板添加字段
export const TemplateAddFieldsApi = (params) =>
  request.post("/admin/work_order_template/add_field", params);
// 工单模板 为模板删除字段
export const TemplateDeleteFieldsApi = (params) =>
  request.post("/admin/work_order_template/delete_field", params);
// 工单模板 指定模板的默认通知人
export const TemplateSetApi = (params) =>
  request.post("/admin/work_order_template/set_notice_admin_user_id", params);

// 自定义字段 字段列表
export const FieldListApi = (params) =>
  request.post("/admin/work_order_field/get_list_data", params);
// 自定义字段 字段选项
export const FieldOptionApi = (params) =>
  request.post("/admin/work_order_field/get_options", params);
// 自定义字段 字段详情
export const FieldInfoApi = (params) =>
  request.post("/admin/work_order_field/get_field", params);
// 自定义字段 新增字段
export const FieldAddApi = (params) =>
  request.post("/admin/work_order_field/add_field", params);
// 自定义字段 更新字段
export const FieldUpdateApi = (params) =>
  request.post("/admin/work_order_field/update_field", params);
// 自定义字段 字段删除
export const FieldDeleteApi = (params) =>
  request.post("/admin/work_order_field/delete_field", params);

// 监控中心 首页数据概览
export const DataOverviewApi = (params) =>
  request.post("/admin/monitoringCenter/dataOverview", params);
// 监控中心 每月统计列表
export const MonthlyStatisticsApi = (params) =>
  request.post("/admin/monitoringCenter/monthlyStatistics", params);
// 监控中心 每日统计列表
export const DailyStatisticsApi = (params) =>
  request.post("/admin/monitoringCenter/dailyStatistics", params);
// 监控中心  查询充电站的月排行榜前10名
export const RankingListApi = (params) =>
  request.post("/admin/monitoringCenter/stationsMonthRankingListTop10", params);

// 按区域汇总返回场站数据
export const GetCityStationsApi = () =>
  request.get("/admin/monitoringCenter/getCityStations");

// 运营中心/场站监控 电量使用情况统计
export const ElectricityUsageApi = (params) =>
  request.post("/admin/stationsMonitor/electricityUsageStatistics", params);
// 运营中心/场站监控 充电枪状态统计
export const ShotsStatusApi = (params) =>
  request.post("/admin/stationsMonitor/shotsStatusStatistics", params);
// 运营中心/场站监控  统计数据
export const StatisticsDataApi = (params) =>
  request.post("/admin/stationsMonitor/statisticsData", params);

//权限列表 获取菜单列表
export const MenuApi = () => request.get("/admin/Menu/menu_list");
//权限列表 获取用户菜单列表
export const MenuListApi = () => request.get("/admin/menu/user_menu_list");
//权限列表 获取用户路由列表
export const RouteListApi = () => request.get("/admin/Menu/user_route_list");
//权限列表 菜单详情
export const MenuGetInfoApi = (params) =>
  request.post("/admin/Menu/get_info", params);
//权限列表 新增菜单
export const MenuAddApi = (params) => request.post("/admin/Menu/add", params);
//权限列表 编辑菜单
export const MenuEditApi = (params) => request.post("/admin/Menu/edit", params);
//权限列表 删除菜单
export const MenuDeleteApi = (params) =>
  request.post("/admin/Menu/delete", params);
//权限列表 更新菜单可用状态
export const UpdateStateApi = (params) =>
  request.post("/admin/Menu/update_state", params);
//权限列表 更新公开状态
export const UpdateAuthOpenApi = (params) =>
  request.post("/admin/Menu/update_auth_open", params);
//权限列表 更新菜单可用状态
export const UpdateMenuStateApi = (params) =>
  request.post("/admin/Menu/update_menu_state", params);
//权限列表 更新菜单排序
export const UpdateSortApi = (params) =>
  request.post("/admin/Menu/update_sort", params);
// 权限列表  获取父菜单列表
export const GetPidListApi = (params) =>
  request.get("/admin/Menu/get_pid_list", params);
// 节点管理 获取用户权限列表
export const GetUserAuthApi = (params) =>
  request.get("/admin/Menu/user_auth_list", params);

// 管理组  获取管理组权限列表
export const GetRuleListApi = (params) =>
  request.post("/admin/AdminGroup/get_rule_list", params);
// 管理组  管理组列表
export const AdminGroupListApi = (params) =>
  request.post("/admin/AdminGroup/admin_group_list", params);
// 管理组  管理组详情
export const GroupGetInfoApi = (params) =>
  request.post("/admin/AdminGroup/get_info", params);
// 管理组  新增管理组
export const GroupAddApi = (params) =>
  request.post("/admin/AdminGroup/add", params);
// 管理组  编辑管理组
export const GroupEditApi = (params) =>
  request.post("/admin/AdminGroup/edit", params);
// 管理组  删除管理员
export const GroupDeleteApi = (params) =>
  request.post("/admin/AdminGroup/delete", params);
// 管理组  设置管理组权限
export const SetAdminGroupApi = (params) =>
  request.post("/admin/AdminGroup/set_admin_group_rule", params);
// 管理组  获取管理组排序信息
export const SortListinfoApi = () =>
  request.get("/admin/AdminGroup/sort_list_info");
// 管理组选项
export const GroupOptionApi = () =>
  request.get("/admin/AdminGroup/get_options");

// 管理员列表
export const AdminListApi = (params) =>
  request.post("/admin/Admin/admin_list", params);
// 管理员详情
export const AdminGetInfoApi = (params) =>
  request.post("/admin/Admin/get_info", params);
// 新增管理员
export const AdminAddApi = (params) => request.post("/admin/Admin/add", params);
// 编辑管理员
export const AdminEditApi = (params) =>
  request.post("/admin/Admin/edit", params);
// 重设管理员密码
export const AdminResetPasswordApi = (params) =>
  request.post("/admin/Admin/reset_password", params);
// 删除管理员
export const AdminDeleteApi = (params) =>
  request.post("/admin/Admin/delete", params);
//管理员列表 获取管理员排序信息
export const AdminSortListinfoApi = () =>
  request.get("/admin/Admin/sort_list_info");
// 管理员 当前登录的管理员信息
export const CurrentUserInfoApi = (params) =>
  request.post("/admin/Admin/current_user_info", params);
// 管理员 重设当前管理员密码
export const ResetCurrentUserApi = (params) =>
  request.post("/admin/Admin/reset_current_user_password", params);

//
export const VerifyUsernameApi = (params) =>
  request.post("/admin/admin/verify_username", params);

// 场站充电数据/收入  最近7天的数据
export const StationDataApi = (params) =>
  request.post("/admin/monitoringCenter/last_7_days_charging_data", params);

// 资源中心/充电站列表
export const StationsListApi = (params) =>
  request.post("/admin/Stations/stations_list", params);
// 资源中心/新增充电站
export const StationsAddApi = (params) =>
  request.post("/admin/Stations/add", params);
// 资源中心/编辑充电站
export const StationsEditApi = (params) =>
  request.post("/admin/Stations/edit", params);
// 资源中心/删除充电站
export const StationsDelApi = (params) =>
  request.post("/admin/Stations/delete", params);
// 获取充电站排序信息
export const StationsSortListInfoApi = () =>
  request.get("/admin/Stations/sort_list_info");
// 资源中心/充电站详情
export const StationGetInfoApi = (params) =>
  request.post("/admin/Stations/get_info", params);
// 资源中心/获取充电站列表
export const StationGetStationsApi = (params) =>
  request.post("/admin/Stations/get_stations_list", params);
// 资源中心/场站电表功率数据
export const ElectricityMeterPowerApi = (params) =>
  request.post("/admin/Stations/electricity_meter_power_record", params);

// 资源中心/能源路由器
export const ControllerOptionsApi = (params) =>
  request.post(
    "/admin/centralized_controller/get_station_centralized_controller_options",
    params
  );
// 路由器监控信息
export const GetMonitorApi = (params) =>
  request.post("/admin/centralized_controller/monitor", params);

// 场站充电桩监控
export const StationPilesMonitorApi = (params) =>
  request.post("/admin/Piles/station_piles_monitor", params);
// 资源中心/充电桩列表
export const PileListApi = (params) =>
  request.post("/admin/Piles/piles_list", params);
// 资源中心/新增充电桩
export const PileAddApi = (params) => request.post("/admin/Piles/add", params);
// 资源中心/编辑充电桩
export const PileEditApi = (params) =>
  request.post("/admin/Piles/edit", params);
// 资源中心/删除充电桩
export const PileDelApi = (params) =>
  request.post("/admin/Piles/delete", params);
// 充电桩排序信息
export const PileSortListApi = () => request.get("/admin/Piles/sort_list_info");
// 获取充电桩列表
export const GetPileListApi = (params) =>
  request.post("/admin/Piles/get_piles_list", params);
// 充电桩详情
export const PileGetInfoApi = (params) =>
  request.post("/admin/Piles/get_info", params);

// 资源中心/充电枪列表   /admin/shots/shots_list
export const ShotsApi = (params) =>
  request.post("/admin/Shots/shots_list", params);
// 资源中心/新增充电枪
export const GunAddApi = (params) => request.post("/admin/Shots/add", params);
// 资源中心/编辑充电枪
export const GunEditApi = (params) => request.post("/admin/Shots/edit", params);
// 资源中心/删除充电枪
export const GunDelApi = (params) =>
  request.post("/admin/Shots/delete", params);
// 获取充电枪排序信息
export const GunSortListApi = () => request.get("/admin/Shots/sort_list_info");
// 导出充电枪列表Excel
export const ExportApi = (params) =>
  request.post("/admin/Shots/out_shots_list_excel", params);
// 充电枪详情

export const GetGunInfoApi = (params) =>
  request.post("/admin/Shots/get_info", params);
// 资源中心/费率列表
export const TariffgroupApi = (params) =>
  request.post("/admin/TariffGroup/tariff_group_list", params);
// 资源中心/费率组详情
export const TariffgroupGetInfoApi = (params) =>
  request.post("/admin/TariffGroup/get_info", params);
// 资源中心/新增费率组
export const TariffgroupAddApi = (params) =>
  request.post("/admin/TariffGroup/add", params);
// 资源中心/编辑费率组
export const TariffgroupEditApi = (params) =>
  request.post("/admin/TariffGroup/edit", params);
// 资源中心/删除费率组
export const TariffgroupDelApi = (params) =>
  request.post("/admin/TariffGroup/delete", params);
// 资源中心/费率组列表
export const GetTariffGroupApi = (params) =>
  request.post("/admin/TariffGroup/get_tariff_group_list", params);
// 资源中心/费率组接口
export const GetPeriodRateListApi = () =>
  request.get("/admin/TariffGroup/get_period_rate_list");
// 资源中心/获取时段费率类型
export const GetPeriodRateTypeApi = () =>
  request.get("/admin/TariffGroup/get_period_rate_type");
// 获取费率组排序信息
export const TariffGroupSortListApi = () =>
  request.get("/admin/TariffGroup/sort_list_info");

// 会员中心/运营商列表
export const CorpListApi = (params) =>
  request.post("/admin/Corp/corp_list", params);
// 会员中心/新增运营商
export const CorpListAddApi = (params) =>
  request.post("/admin/Corp/add", params);
// 会员中心/编辑运营商
export const CorpListEditApi = (params) =>
  request.post("/admin/corp/update", params);
// 会员中心/删除运营商
export const CorpListDelApi = (params) =>
  request.post("/admin/Corp/delete", params);
// 会员中心/更新审核状态
export const UpdateAuditStatusApi = (params) =>
  request.post("/admin/Corp/update_audit_status", params);
// 会员中心/更新运营商状态
export const UpdateStatusApi = (params) =>
  request.post("/admin/Corp/update_status", params);
// 会员中心/获取运营商列表
export const GetCorpListApi = (params) =>
  request.post("/admin/Corp/get_corp_list", params);
// 会员中心/运营商详情
export const GetInfoApi = (params) =>
  request.post("/admin/Corp/get_info", params);
// 会员中心/获取运营商排序信息
export const SortListInfoApi = () => request.get("/admin/Corp/sort_list_info");
// 获取城市
export const CitytApi = (params) =>
  request.post("/admin/City/get_city", params);

// 活动管理 活动列表
export const ActivityListApi = (params) =>
  request.post("/admin/activity/list", params);
// 活动管理 获取排序信息
export const ActivitySortApi = () =>
  request.get("/admin/activity/sort_list_info");
// 活动管理 添加活动
export const ActivityAddApi = (params) =>
  request.post("/admin/activity/add", params);
// 活动管理 更新活动
export const ActivityUpdateApi = (params) =>
  request.post("/admin/activity/update", params);
// 活动管理 删除活动
export const ActivityDeleteApi = (params) =>
  request.post("/admin/activity/delete", params);
// 活动管理 活动详情
export const ActivityInfoApi = (params) =>
  request.post("/admin/activity/info", params);

// 系统管理/管理员列表
export const UserListApi = (params) =>
  request.post("/admin/user/user_list", params);
// 系统管理/新增管理员
export const UserListAddApi = (params) =>
  request.post("/admin/user/add", params);
// 系统管理/编辑管理员  /admin/user/edit
export const UserListEditApi = (params) =>
  request.post("/admin/user/edit", params);
// 系统管理/删除管理员  /admin/user/delete
export const UserListDelApi = (params) =>
  request.post("/admin/user/delete", params);

// 运营中心/充电桩控制 / 获取充电桩排序信息
export const PilesControlSortListApi = () =>
  request.get("/admin/PilesControl/sort_list_info");
// 运营中心 充电桩控制列表
export const PilesControlApi = (params) =>
  request.post("/admin/PilesControl/index", params);
// 运营中心/下发费率
export const PilesControltariffApi = (params) =>
  request.post("/admin/PilesControl/issue_tariff_group", params);

// webSocket
export const LoginWebSocketApi = (params) =>
  request.post("/admin/Login/web_socket", params);

// 发票管理 开票记录
export const ElectronicInvoiceApi = (params) =>
  request.post("/admin/electronic_invoice/list", params);
// 发票管理 开票统计
export const InvoiceStatisticApi = (params) =>
  request.post("/admin/electronic_invoice/statistics", params);

//财务中心/订单管理
export const GetOrderListApi = (params) =>
  request.post("/admin/Order/get_order_list", params);
//财务中心/订单详情
export const GetOrderInfoApi = (params) =>
  request.post("/admin/Order/get_order_info", params);
//财务中心/订单修复
export const OrderRepairApi = (params) =>
  request.post("/admin/Order/order_repair", params);

// 财务中心/导出电子表格
export const ExportSpreadsheetApi = (params) =>
  request.post("/admin/Order/export_spreadsheet", params);
// 财务中心/充电订单汇总
export const GetOrderSummaryApi = (params) =>
  request.post("/admin/Order/get_order_summary", params);

// 财务中心/退款订单列表
export const GetRefundOrderlistApi = (params) =>
  request.post("/admin/RefundOrder/refund_order_list", params);
// 财务中心/获取退款订单排序信息
export const RefundSortListApi = () =>
  request.get("/admin/RefundOrder/sort_list_info");
// 财务中心/退款订单详情
export const GetRefundInfoApi = (params) =>
  request.post("/admin/RefundOrder/get_info", params);
// 财务中心/同意退款
export const GetAgreeRefundApi = (params) =>
  request.post("/admin/RefundOrder/agree_and_refund", params);
// 财务中心/拒绝退款
export const GetRefuseRefundApi = (params) =>
  request.post("/admin/RefundOrder/refuse_refund", params);
// 退款成功
export const GetRefundSuccessApi = (params) =>
  request.post("/admin/RefundOrder/refund_success", params);

// 财务中心 充值订单列表
export const RechargeOrderListApi = (params) =>
  request.post("/admin/RechargeOrder/refund_order_list", params);
// 财务中心 获取充值订单排序信息
export const RechargeOrderSortListApi = () =>
  request.get("/admin/RechargeOrder/sort_list_info");
// 财务中心 充值订单详情
export const RechargeOrderInfoApi = (params) =>
  request.post("/admin/RechargeOrder/get_info", params);

// 用户中心 用户列表
export const UsersListApi = (params) =>
  request.post("/admin/Users/users_list", params);
// 用户中心 用户详情
export const UsersInfoApi = (params) =>
  request.post("/admin/Users/get_info", params);
// 用户中心 用户列表排序信息
export const UsersSortListApi = () =>
  request.get("/admin/Users/sort_list_info");
// 用户中心 用户统计
export const UsersStatisticsApi = (params) =>
  request.post("/admin/Users/user_statistics", params);

// 告警记录列表
export const AlarmRecordApi = (params) =>
  request.post("/admin/AlarmRecord/get_list_data", params);
// 删除告警记录
export const AlarmRecordDeleteApi = (params) =>
  request.post("/admin/AlarmRecord/delete_data", params);

// 主动告警管理 获取主动告警配置
export const ActiveAlarmGetApi = (params) =>
  request.post("/admin/active_alarm/get_config", params);
// 主动告警管理 更新主动告警配置
export const ActiveAlarmUpdateApi = (params) =>
  request.post("/admin/active_alarm/update_config", params);
// 主动告警管理 停止原因选项
export const ActiveAlarmReasonApi = (params) =>
  request.post("/admin/active_alarm/reason_for_stop_options", params);

// 场站主动告警 场站配置
export const StationAlarmGetApi = (params) =>
  request.post("/admin/stations_active_alarm_config/get_list_data", params);
// 场站主动告警 获取主动告警配置
export const StationAlarmGetActiveApi = (params) =>
  request.post(
    "/admin/stations_active_alarm_config/get_active_alarm_config",
    params
  );

export const StationAlarUpdateActiveApi = (params) =>
  request.post(
    "/admin/stations_active_alarm_config/update_active_alarm_config",
    params
  );

export const GetCorpListAllApi = () => request.get("/admin/Corp/get_corp_list");
export const GetStationsClearingListApi = (params) =>
  request.post("/admin/stations/get_stations_clearing_list", params);
export const SaveStationsClearingApi = (params) =>
  request.post("/admin/stations/save_stations_clearing", params);
export const OrderClearingListApi = (params) =>
  request.post("/admin/Clearing/order_clearing_list", params);
export const OrderClearingSortListInfoApi = () =>
  request.get("/admin/Clearing/order_clearing_sort_list_info");
export const CorpClearingListApi = (params) =>
  request.post("/admin/Clearing/corp_clearing_list", params);
export const StationsClearingListApi = (params) =>
  request.post("/admin/Clearing/stations_clearing_list", params);
export const GetOrderClearingStatusListApi = () =>
  request.get("/admin/Clearing/get_order_clearing_status_list");
export const GetStationsListAllApi = () =>
  request.get("/admin/Stations/get_stations_list_all");
export const OrderSettleAccountsApi = (params) =>
  request.post("/admin/Clearing/order_settle_accounts", params);

export const CorpClearingSortListInfoApi = () =>
  request.get("/admin/Clearing/corp_clearing_sort_list_info");
export const StationsClearingSortListInfoApi = () =>
  request.get("/admin/Clearing/stations_clearing_sort_list_info");
//获取用户权限列表
// export const UserAuthApi = (params) =>
//   request.post("/admin/sys/Menu/user_auth_list", params);

// 报表管理  报表故障记录
export const DeviceRecordListApi = (params) =>
  request.post("/admin/report_form/device_failure_record_list", params);

// 报表管理  报表故障记录(用于导出)
export const DeviceRecordApi = (params) =>
  request.post("/admin/report_form/export_device_failure_record", params);
// 超级管理员的运营资金列表
export const OperateFundListApi = (params) =>
  request.post("/admin/report_form/operating_funds_list", params);
// 超级管理员的运营资金列表(用于导出)
export const ExportOperateApi = (params) =>
  request.post("/admin/report_form/export_operating_funds", params);
// 运营商的运营资金列表
export const CorpOperateFundListApi = (params) =>
  request.post("/admin/report_form/corp_operating_funds_list", params);
//运营商的运营资金列表(用于导出)
export const ExportCorpOperateApi = (params) =>
  request.post("/admin/report_form/export_corp_operating_funds", params);
// 运营扫码表数据列表
export const ScanCodeListApi = (params) =>
  request.post("/admin/report_form/scan_code_records_list", params);
// 运营扫码表数据(用于导出)
export const ExportScanCodeApi = (params) =>
  request.post("/admin/report_form/export_scan_code_records", params);
// // 运营订单表数据
export const ReportOrderListApi = (params) =>
  request.post("/admin/report_form/order_list", params);
// 运营订单表数据(用于导出)
export const ExportOrderApi = (params) =>
  request.post("/admin/report_form/export_order_list", params);

// 重启充电  获取充电订单选项
export const GetOrderOptionApi = (params) =>
  request.post("/admin/order/options", params);
//根据充电订单ID查询用户信息
export const UserOrderIDApi = (params) =>
  request.post("/admin/users/get_user_info_by_order_id", params);
// 重启订单
export const ReStartOrderApi = (params) =>
  request.post("/admin/debug_tool/restart_order", params);
// 获取订单状态信息
export const GetOrderStatusApi = (params) =>
  request.post("/admin/order/get_order_status_info", params);

// 优惠券活动 活动列表
export const GetCouponListApi = (params) =>
  request.post("/admin/coupon_activity/list", params);
// 优惠券活动 创建优惠券活动
export const CreateCouponApi = (params) =>
  request.post("/admin/coupon_activity/create_activity", params);
// 优惠券活动 更新优惠券活动
export const UpdateCouponApi = (params) =>
  request.post("/admin/coupon_activity/update_activity", params);
// 优惠券活动 添加现金券
export const AddCouponApi = (params) =>
  request.post("/admin/coupon_activity/add_pattern", params);
// 优惠券活动 提交发放审核
export const SubmitCouponApi = (params) =>
  request.post("/admin/coupon_activity/submit_review", params);
// 优惠券活动 活动发放审核通过
export const ReviewCouponApi = (params) =>
  request.post("/admin/coupon_activity/grant_review_adopt", params);
// 活动发放审核不通过
export const FailedCouponApi = (params) =>
  request.post("/admin/coupon_activity/grant_review_failed", params);
// 停用活动
export const StopCouponApi = (params) =>
  request.post("/admin/coupon_activity/stop_activity", params);
// 停用活动审核通过
export const StopReviewCouponApi = (params) =>
  request.post("/admin/coupon_activity/stop_activity_review_adopt", params);
// 停用活动审核不通过
export const StopFailedCouponApi = (params) =>
  request.post("/admin/coupon_activity/stop_activity_review_failed", params);

// 获取排序信息
export const CouponSortApi = () =>
  request.get("/admin/coupon_activity/sort_list_info");
// 获取活动详情
export const CouponInfoApi = (params) =>
  request.post("/admin/coupon_activity/info", params);
// 删除优惠券活动
export const DeleteCouponApi = (params) =>
  request.post("/admin/coupon_activity/delete_activity", params);
// 现金券列表
export const PatternCouponApi = (params) =>
  request.post("/admin/coupon_activity/pattern_list", params);
// 获取现金券列表排序信息
export const CouponPatternSortApi = () =>
  request.get("/admin/coupon_activity/pattern_sort_list_info");
// 现金券详情
export const PatternCouponInfoApi = (params) =>
  request.post("/admin/coupon_activity/pattern_info", params);
// 更新现金券
export const UpdatePatternApi = (params) =>
  request.post("/admin/coupon_activity/update_pattern", params);
// 删除现金券
export const DeletePatternApi = (params) =>
  request.post("/admin/coupon_activity/delete_pattern", params);
// 用户优惠券 用户优惠券列表
export const CouponCollectionApi = (params) =>
  request.post("/admin/coupon_collection/list", params);
// 用户优惠券 获取列表排序信息
export const CouponCollectSortApi = () =>
  request.get("/admin/coupon_collection/sort_list_info");
// 用户优惠券 用户优惠券详情
export const CouponCollectionInfoApi = (params) =>
  request.post("/admin/coupon_collection/info", params);
// 活动选项
export const CouponActivityApi = (params) =>
  request.post("/admin/coupon_activity/activity_options", params);

//操作日志列表
export const CouponLogListApi = (params) =>
  request.post("/admin/coupon_activity/oplog_list", params);
// 操作日志排序信息
export const CouponLogSortApi = () =>
  request.get("/admin/coupon_activity/oplog_sort_list_info");

//充电二维码 生成二维码
export const CreateQrApi = (params) =>
  request.post("/admin/charging_qrcode/generate_qrcode", params);
// 充电二维码 二维码列表
export const QrListApi = (params) =>
  request.post("/admin/charging_qrcode/list", params);
// 二维码排序
export const QrSortListApi = () =>
  request.get("/admin/charging_qrcode/sort_list_info");
